<template>
  <div>
    <b-modal
      id="change-status-recycle"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('RecycleStatus')"
      no-close-on-backdrop
      size="s"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          label-for="input-2"
        >
          <p v-if="stateOfRecycle == 'InStock'">
            {{ $t("RecycleMode") }}
          </p>
          <p v-else>
            {{ $t("StockMode") }}
          </p>
        </b-form-group>
        <div style="display: flex; flex-direction: column;gap: 10px">
          <b-form-group
            style="width: 100%;"
            :label="$t('Location')"
          >
            <vue-select
              v-model="form.locationId"
              :options="getLocationsByType"
              label="locationName"
              :placeholder="$t('Select')"
              :reduce="e => e.locationId"
            />
          </b-form-group>
          <b-form-group
            style="width: 100%;"
            :label="$t('Notes')"
          >
            <b-form-textarea
              v-model="form.notes"
            />
          </b-form-group>
        </div>
        <pre />
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="submit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 5px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['productInStockIdRecycle', 'stateOfRecycle'],
  data() {
    return {
      isTeamLeader: false,
      isCoTeamLeader: false,
      userId: null,
      teamLeader: null,
      manager: null,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      form: {
        articleNumber: null,
        newState: null,
        locationId: null,
      },
    };
  },
  computed: {
    ...mapGetters(['getFreeManagers', 'getTeamsNames', 'getLocationsByType']),
  },

  watch: {
    stateOfRecycle(value) {
      if (value == 'Recycle') {
        this.form.newState = 'InStock'
      } else {
        this.form.newState = 'Recycle'
      }
    },
    productInStockIdRecycle(value) {
      this.form.articleNumber = value
    },
  },
  mounted() {
    // this.form.storeId = this.storeId
  },
  methods: {
    ...mapActions(['loadUsersFullNamesById']),
    submit() {
      this.$emit('recycle', this.form);
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.locationId = null;
      this.form.notes = null
    },
  },
};
</script>

<style lang="scss" scoped>

.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
